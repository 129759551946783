const data = [
    {
        "id": 1,
        "group": "Indoor Show Group",
        "name": "COM",
        "dimming": 15
    },
    {
        "id": 2,
        "group": "Indoor Show Group",
        "name": "CLASS II",
        "dimming": 25
    },
    {
        "id": 3,
        "group": "Indoor Show Group",
        "name": "TOUR",
        "dimming": 55
    },
    {
        "id": 4,
        "group": "Indoor Show Group",
        "name": "HD BCAST",
        "dimming": 100
    },
    {
        "id": 5,
        "group": "Indoor Practice Group",
        "name": "COM",
        "dimming": 30
    },
    {
        "id": 6,
        "group": "Indoor Practice Group",
        "name": "CLASS II",
        "dimming": 50
    },
    {
        "id": 7,
        "group": "Indoor Practice Group",
        "name": "TOUR",
        "dimming": 100
    },
    {
        "id": 8,
        "group": "Outdoor Tennis Group",
        "name": "CLASS II",
        "dimming": 30
    },
    {
        "id": 9,
        "group": "Outdoor Tennis Group",
        "name": "TOUR",
        "dimming": 100
    },
    {
        "id": 10,
        "group": "Junior Group",
        "name": "CLASS II",
        "dimming": 40
    },
    {
        "id": 11,
        "group": "Junior Group",
        "name": "TOUR",
        "dimming": 100
    },
    {
        "id": 12,
        "group": "Rooftop Group",
        "name": "CLASS II",
        "dimming": 40
    },
    {
        "id": 13,
        "group": "Rooftop Group",
        "name": "TOUR",
        "dimming": 100
    }
];

const group_mode = {
    'indoor_show_group': 'Indoor Show Group',
    'indoor_practice_group': 'Indoor Practice Group',
    'outdoor_tennis_group': 'Outdoor Tennis Group',
    'junior': 'Junior Group',
    'rooftop_group': 'Rooftop Group'
}

export default {
    group_mode,
    data
};