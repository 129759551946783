<template>
  <div id="profile-wrapper">
    <v-row no-gutters>
      <v-col cols="12" class="main-content">
        <div>
          <v-row id="top-form" class="box-height1 user-profile-form" no-gutters>
            <v-col cols="6" class="first-col">
              <v-row class="fill-height" no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p class="profile-account">Username: <span>{{ userName }}</span></p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12 pt-3">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <p class="profile-account">Password: <span class="display-password">✱✱✱✱✱✱✱✱</span></p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" class="second-col">
              <v-col cols="12" class="second-col-body">
                <v-row no-gutters style="visibility: hidden;"> <p>Change Password</p> </v-row>
                <v-row no-gutters align="center">
                  <v-col cols="12" class="btn-change-password">
                    <v-btn
                      :ripple="false"
                      class="form-btn"
                      style="height: 40px; width: auto"
                      @click="modalChangePassword = true"
                    >
                      Change Password
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      persistent
      content-class="popup-password-notification"
    >
      <v-card id="password-notification" class="dialog_content">
        <v-card-title style="background-color: #111111;">{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="message_content">
              <div class="message_content_detail"> {{ apiMessage }} </div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary btn_black"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalChangePassword"
      max-width="500"
      persistent
      content-class="profile-confirm-dialog password-modal"
    >
      <v-card id="profile-confirm" class="dialog-change-password password-modal-card">
        <button @click="closeChangePasswordDialog" class="close-modal">✖</button>
        <v-card-title class="password-heading">Change Password</v-card-title>
        <v-card-text
          class="password-content"
        >
          <v-form
            ref="formChangePassword"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters class="fill-height">
                  <v-col cols="12">
                    <v-row no-gutters class="half-height">
                      <v-col cols="12">
                        <v-row no-gutters class="change-password-title"><p>Current Password:</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            v-model="oldPassword"
                            required
                            :type="showOldPass ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="oldPasswordRules"
                            outlined
                            filled
                            placeholder="Enter Current Password"
                            class="change-password-field fontello-password"
                            @input="handleInputCurrentPassword"
                          >
                          </v-text-field>
                        </v-row>
                      </v-col>
                      <v-col cols="12 pt-1">
                        <v-row no-gutters class="change-password-title"><p>New Password:</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            v-model="newPassword"
                            required
                            :type="showNewPass ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="newPasswordRules"
                            outlined
                            filled
                            placeholder="Enter New Password"
                            class="change-password-field fontello-password"
                          >
                          </v-text-field>
                        </v-row>
                      </v-col>
                      <v-col cols="12 pt-1">
                        <v-row no-gutters class="change-password-title"><p>Re-enter Your New Password:</p></v-row>
                        <v-row no-gutters>
                          <v-text-field
                            v-model="ConfirmPassword"
                            required
                            :type="showConfirmPassword ? 'text' : 'password'"
                            autocomplete="off"
                            :rules="confirmPassRules"
                            outlined
                            filled
                            placeholder="Re-enter New Password"
                            class="change-password-field fontello-password"
                          >
                          </v-text-field>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center custom-change-password">
                <button
                  class="btn custom-btn-change-password"
                  type="submit"
                >
                  Change password
                </button>
              </v-col>
            </v-row>
          </v-form>
          </v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" src="@/assets/css/user-profile.scss"></style>
<script>
import moment from "moment";
import { userProfileService, auth } from "../services";
export default {
  data() {
    return {
      userName: "",
      userGroup: "",
      userGroupId: "",
      userSite: "",
      lastLogin: "",
      oldPassword: "",
      newPassword: "",
      ConfirmPassword: "",
      dialog: false,
      showOldPass: false,
      showNewPass: false,
      showConfirmPassword: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      passwordUpdated: false,
      newPasswordRules: [
        (v) => {
          if (!v) return "New password invalid. Please try again";
          if (v.length < 8 || v.length > 10) return "New password invalid. Please try again";
          if (!/(?=.*[A-Z])/.test(v)) return "New password invalid. Please try again";
          if (!/(?=.*[!@#$%^&+=*_-])/.test(v)) return "New password invalid. Please try again";
          if (!/(?=.*\d)/.test(v)) return "New password invalid. Please try again";
          return true;
        }
      ],
      userProfile: {},
      valid: false,
      editValid: false,
      fileRule: [
        (value) =>
          !value ||
          value.size <= 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      modalChangePassword: false,
      timeoutCheckPassword: null,
      messageInvalidPassword: false,
    };
  },
  async created() {
    await userProfileService
      .getCurrentUserProfile()
      .then((res) => {
        if (res.status == 200 && res?.data?.data) {
          this.setUserProfile(res.data.data);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          this.apiMessage = err.response.data.message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        }
      });
  },
  computed: {
    confirmPassRules() {
      return [
        this.ConfirmPassword === this.newPassword || "Password don’t match. Please try again",
      ];
    },
    oldPasswordRules() {
      return [
        !this.messageInvalidPassword || "Incorrect password entered. Please try again",
      ];
    }
  },
  methods: {
    setUserProfile(data) {
      this.userName = data.username || "";
      this.userGroup = data.user_group?.name || "";
      this.userGroupId = data.user_group_id || "";
      this.userSite = data.user_site || "N/A";
      this.valid = !!data.email;
      this.lastLogin = data.last_login
        ? moment(data.lastLogin).format("DD-MM-YYY HH:mm:ss")
        : "Active Now";
      this.userProfile = Object.assign({}, this.userProfile, data);
    },
    revert() {
      this.setUserProfile(this.userProfile);
      this.showOldPass = false;
      this.showNewPass = false;
      this.showConfirmPassword = false;
    },
    triggerFileChange() {
      this.$refs.file.$refs.input.click();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.editForm.reset();
    },
    handleInputCurrentPassword() {
      if (this.timeoutCheckPassword) {
        clearTimeout(this.timeoutCheckPassword);
      }
      this.timeoutCheckPassword = setTimeout(() => {
        this.checkValidCurrentPassword();
      }, 500);
    },
    checkValidCurrentPassword() {
      if(this.oldPassword.length < 8 || this.oldPassword.length > 10) {
        this.messageInvalidPassword = true;
      } else {
        const data = {
          current_password: this.oldPassword,
          is_check_password: 1,
        };
        userProfileService
          .updateUserPassword(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.messageInvalidPassword = false;
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              this.messageInvalidPassword = true;
            }
          });
      }
    },
    submitEdit() {
      if (this.$refs.formChangePassword.validate()) {
        const data = {
          current_password: this.oldPassword,
          new_password: this.newPassword,
        };
        let vm = this;
        userProfileService
          .updateUserPassword(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            vm.revert();
            vm.apiTitle = "Password Successfully Updated";
            vm.apiMessage = "Password has been successfully updated. Please log in again with your new password.";
            vm.passwordUpdated = true;
            vm.messageDialog = true;
            vm.modalChangePassword = false;
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              this.apiMessage = err.response.data.message;
              this.apiTitle = "Error";
              this.messageDialog = true;
            }
          });
      }
    },
    closeMessage() {
      this.messageDialog = false;
      if(this.passwordUpdated) {
        this.logout();
        this.modalChangePassword = false;
      }
    },
    closeChangePasswordDialog(){
      this.$refs.formChangePassword.reset();
      this.modalChangePassword = false;
    },
    logout() {
      auth.logout().finally(() => {
        this.$store.dispatch("logout").then(() => location.reload());
      });
    },
  },
};
</script>

<style scoped>
.v-text-field__details {
  margin-bottom: 0px !important;
}

#password-notification.dialog_content{
  background-color: #111111; 
  border-radius: 8px !important; 
  border: 1px solid #b1b2b3;
}

#password-notification.dialog_content .v-card__title{
  padding: 24px;
  justify-content: center;
  font-weight: bold;
}

#password-notification.dialog_content .v-card__text{
  margin-top: 0;
  padding: 0 24px 24px;
}

#password-notification.dialog_content .v-card__text .message_content{
  padding-left: 30px; 
  padding-right: 30px; 
  text-align: center;
  font-size: 20px;
}
#password-notification.dialog_content .v-card__text .message_content .message_content_detail{
  padding: 0 10px;
  line-height: 24px;
}

#password-notification.dialog_content .v-card__text .close_btn_content{
  text-align: center;
  padding-top: 40px;
}

#password-notification.dialog_content .v-card__text .close_btn_content .btn_black{
  background-color: #010101;
  border-color: #b1b2b3;
  border-radius: 5px;
  padding: 8px 100px;
}

#password-notification.dialog_content .v-card__text .close_btn_content .btn_black:hover{
  background-color: #292929;
}
</style>
