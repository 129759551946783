import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import global from "@/plugins/global.js";
import moment from 'moment-timezone';
import TurnOffAutocomplete from 'vue-turn-off-autocomplete';

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import BootstrapVue from "bootstrap-vue";

import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "bootstrap/dist/css/bootstrap.css";

moment.tz.setDefault('Asia/Singapore');

Vue.use(BootstrapVue);
Vue.use(global);
Vue.use(TurnOffAutocomplete);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
