import { scheduleService } from "../../services";
import moment from "moment";

const DATE_FORMAT_SCHEDULE = "YYYY-MM-DD";
const HOUR_FORMAT = "HH:mm";
const COLOR_SCHEDULE_ONGOING = "#FCD5B5";
const COLOR_SCHEDULE_PENDING = "#F79646";
const BORDER_BAR = "1px solid #707070";
const DRAG_EVENT_BLOCKED = true;

function NOW_DATE() {
  return moment();
}

async function getSchedules(params) {
  let dataSchedules = [];
  await scheduleService
    .getListRecurring(params)
    .then((res) => {
      if (res.status !== 200 || (res.data && !res.data.success)) throw res;
      dataSchedules = res.data.data;
    })
    .catch((err) => {
      let message = "Get schedules failed";
      if (err?.response?.data?.message) {
        message = err.response.data.message;
      }
      return {
        error: true,
        message: message,
      };
    });
  return dataSchedules;
}

function getStyleBar(bar) {
  if (NOW_DATE().isBetween(moment(bar.start_time), moment(bar.end_time))) {
    return { color: "#212121", backgroundColor: COLOR_SCHEDULE_ONGOING };
  }
  return { color: "#FFFFFF", backgroundColor: COLOR_SCHEDULE_PENDING };
}

function customData(dataSchedules, daysBetween) {
  let newData = [];
  let nowDay = NOW_DATE().format(DATE_FORMAT_SCHEDULE);
  for (let day of daysBetween) {
    let _day = day.format(DATE_FORMAT_SCHEDULE);
    let dataInDay = dataSchedules.filter((item) => {
      return item.date == _day;
    });
    dataInDay = dataInDay.map((item) => {
      let style = getStyleBar(item);
      item = {
        group_id: item.group_id,
        date: item.date,
        end_time: item.end_time,
        ganttBarConfig: {
          id: Date.now(),
          border: BORDER_BAR,
          borderRadius: "0",
          color: style.color,
          cursor: "pointer",
          backgroundColor: style.backgroundColor,
          handles: true,
          immobile: DRAG_EVENT_BLOCKED,
        },
        id: item.id,
        mode: item.mode,
        myStart: nowDay + " " + moment(item.start_time).format(HOUR_FORMAT),
        myEnd: nowDay + " " + moment(item.end_time).format(HOUR_FORMAT),
        checkMyStart: moment(item.date).format(DATE_FORMAT_SCHEDULE) + " " + moment(item.start_time).format(HOUR_FORMAT),
        checkMyEnd: moment(item.date).format(DATE_FORMAT_SCHEDULE) + " " + moment(item.end_time).format(HOUR_FORMAT),
        start_time: item.start_time,
        recurring_id: item.recurring_id,
        option: "this_event",
        checkRecurrence: false,
        holiday: false,
      };
      return item;
    });
    newData.push({
      label: day.format("ddd DD MMM"),
      bars: dataInDay,
      date: _day,
    });
  }
  return newData;
}

const schedules = {
  namespaced: true,
  state: {
    schedules: [],
    dataSchedules: [],
    scheduleIndex: null,
    toPath: null
  },
  actions: {
    async getSchedules({ commit }, params) {
      let schedules = await getSchedules(params);
      commit("customDataSchedules", schedules);
      return schedules;
    },
    async customDataSchedules({ commit }, data) {
      let _data = await customData(data.dataSchedules, data.daysBetween);
      commit("customDataSchedules", _data);
    },
    setScheduleIndexFunc({ commit }, data) {
      commit("setScheduleIndexFunc", data);
    },
    setToPath({ commit }, path) {
      commit("setToPath", path);
    },
  },
  mutations: {
    setSchedules(state, schedules) {
      state.schedules = schedules;
    },
    customDataSchedules(state, _data) {
      state.dataSchedules = _data;
    },
    setScheduleIndexFunc(state, _data) {
      state.scheduleIndex = _data;
    },
    setToPath(state, path) {
      state.toPath = path;
    },
  },
  getters: {
    getSchedules(state) {
      return state.schedules;
    },
    getDataSchedules(state) {
      return state.dataSchedules;
    },
    getScheduleIndexFunc(state) {
      return state.scheduleIndex;
    },
  },
};

export default schedules;
