import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import { noAuthRoutes } from '../constants'
import moment from 'moment';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/change-password/:token',
    name: 'Change Password',
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      permissions: 'dashboard'
    },
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      permissions: 'user'
    },
    component: () => import('../views/users/Users.vue'),
    children: [
      {
        path: 'groups',
        name: 'Group Management',
        meta: {
          permissions: 'user'
        },
        component: () => import('../views/users/GroupManagement.vue')
      },
      {
        path: 'list',
        name: 'User Management',
        meta: {
          permissions: 'user'
        },
        component: () => import('../views/users/UserManagement.vue')
      },
      {
        path: 'reset-password',
        name: 'User Reset Password',
        meta: {
          permissions: 'user'
        },
        component: () => import('../views/users/UserResetPassword.vue')
      },
    ]
  },
  {
    path: '/schedule',
    name: 'Schedule',
    meta: {
      permissions: 'schedule'
    },
    component: () => import('../views/schedule/ScheduleManagement.vue')
  },
  {
    path: '/settings',
    name: 'Dimming Setting',
    meta: {
      permissions: 'setting'
    },
    component: () => import('../views/users/Settings.vue')
  },
  {
    path: '/logs',
    name: 'Logs',
    meta: {
      permissions: 'log'
    },
    component: () => import('../views/logs/Logs.vue'),
    children: [
      {
        path: 'users',
        name: 'Users Logs',
        meta: {
          permissions: 'log'
        },
        component: () => import('../views/logs/UserLogs.vue')
      },
      {
        path: 'devices',
        name: 'Devices Logs',
        meta: {
          permissions: 'log'
        },
        component: () => import('../views/logs/DeviceLogs.vue')
      },
      {
        path: 'sc',
        name: 'Sc Logs',
        meta: {
          permissions: 'log'
        },
        component: () => import('../views/logs/ScStatusLogs.vue')
      },
      {
        path: 'schedules',
        name: 'Schedule Logs',
        meta: {
          permissions: 'log'
        },
        component: () => import('../views/logs/ScheduleLogs.vue')
      },
    ]
  },
  {
    path: '/public-holiday',
    name: 'Public Holiday',
    component: () => import('../views/public-holiday/PublicHoliday.vue')
  },
  {
    path: '/usage-trend',
    name: 'Usage Trend',
    component: () => import('../views/energy-consumption/EnergyConsumption.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

let accessibleRoutes = store.getters.accessibleRoutes;
router.beforeEach((to, from, next) => {
  if (noAuthRoutes.findIndex(el => el == to.name) < 0 && !store.getters.isLoggedIn) {
    next({ name: 'Login' })
  } else {
    if (from.path == '/schedule') {
      let vm = store.state.schedules.scheduleIndex;
      if (vm && vm.activeCourt && (vm.activeNew || (vm.scheduleData && vm.scheduleData.id && moment(vm.scheduleData.date + " " + vm.scheduleData.end_time)) > moment())) {
        store.commit("schedules/setToPath", to.path);
        vm.activeClick = 'change-page';
        vm.showModalContinuate = true;
      } else {
        next()
      }
    } else {
      if (to.meta && to.meta.permissions) {
        if (typeof accessibleRoutes[to.meta.permissions]['view'] != "undefined" && accessibleRoutes[to.meta.permissions]['view']) {
          if (to.path == '/logs') {
            next({ name: 'Users Logs' })
          } else if (to.path == '/users') {
            next({name: 'User Management'})
          } else {
            next()
          }
        } else {
          next({ name: '404' })
        }
      } else {
        next()
      }
    }
  }
})

export default router
