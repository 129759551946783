import HTTP_SERVICE from './http-service';

export default {
    checkHolidayExist(data) {
        return HTTP_SERVICE.get('/checkHolidayExist', {params: data});
    },
    getListHolidays(params) {
        return HTTP_SERVICE.get('/holidays' + (params ?? ''));
    },
    getHoliday(id) {
        return HTTP_SERVICE.get(`/holidays/${id}`);
    },
    storeHoliday(data) {
        return HTTP_SERVICE.post('/holidays', data);
    },
    updateHoliday(id, data) {
        return HTTP_SERVICE.put(`/holidays/${id}`, data);
    },
    deleteHoliday(id) {
        return HTTP_SERVICE.delete(`/holidays/${id}`);
    },
}