import HTTP_SERVICE from './http-service';

export default {
    getListUsers() {
        return HTTP_SERVICE.get('/users');
    },
    getUser(id) {
        return HTTP_SERVICE.get(`/users/${id}`);
    },
    storeUser(data) {
        return HTTP_SERVICE.post('/users', data);
    },
    updateUser(id, data) {
        return HTTP_SERVICE.put(`/users/${id}`, data);
    },
    resetPassForUser(id, data) {
        return HTTP_SERVICE.put(`/users/${id}/reset-password`, data);
    },
    unlockUser(id) {
        return HTTP_SERVICE.put(`/users/${id}/unlock`);
    },
    deleteUser(id) {
        return HTTP_SERVICE.delete(`/users/${id}`);
    },
    getListResetPasswordUsers() {
        return HTTP_SERVICE.get('/list-reset-password-users');
    },
    resetPasswordUsers(id) {
        return HTTP_SERVICE.put(`/users/${id}/reset-password`);
    },
}