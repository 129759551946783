import axios from 'axios';
import store from '../store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API + "/api/v1",
  timeout: 5000
});

if (localStorage.getItem('token')) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  instance.defaults.headers.common["Content-Type"] = "application/json";
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      const errorBrowser = error.response.data.message;
      store.commit('setErrorBrowser', errorBrowser);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    return error;
  }
);

export default {
  instance,
  get(url, params) {
    return instance.get(url, params);
  },
  post(url, params) {
    return instance.post(url, params);
  },
  put(url, params) {
    return instance.put(url, params);
  },
  delete(url, params) {
    return instance.delete(url, {data: JSON.stringify(params)});
  }
}